<template>
    <section
        class="c-hero"
        :style="style"
        :class="{
            'c-hero--edit': inEditMode,
            'c-hero--hideoverflow': heroVideo,
        }"
    >
        <link
            v-if="heroImage"
            rel="prefetch"
            href="url(' + this.heroImage + '?w=3000)"
        />
        <div v-if="heroVideo" class="c-hero__video">
            <MediaBlock
                :videoEmbedCode="
                    heroVideo.content && heroVideo.content.videoEmbedCode
                "
            />
        </div>
        <div v-if="overlay" class="c-hero__overlay"></div>
        <Container class="c-hero__content-wrapper">
            <div class="c-hero__content">
                <h1
                    class="c-hero__content-title"
                    :class="{
                        'c-hero__content-title--colored': changeTitleColor,
                        'c-hero__content-title--less-margin': subtitle,
                    }"
                    v-epi-edit="'Title'"
                    v-html="title"
                ></h1>
                <XhtmlField v-if="subtitle || isEditable" :items="subtitle" />
                <Button
                    v-if="url"
                    isLarge
                    :href="url"
                    class="c-hero__button"
                    :buttonStyle="changeLinkColor ? 'filled' : null"
                    >{{ buttonText }}</Button
                >
                <Button
                    v-if="url2"
                    isLarge
                    :href="url2"
                    class="c-hero__button"
                    :buttonStyle="changeLinkColor ? 'filled' : null"
                    >{{ buttonText2 }}</Button
                >
                <epi-property
                    v-if="heroImage"
                    v-show="!epiDisableEditing"
                    property-name="HeroImage"
                ></epi-property>
            </div>
        </Container>
    </section>
</template>

<script>
import EpiProperty from '@/Scripts/components/EpiProperty.vue';
import Button from '@/Scripts/components/atoms/Button.vue';

import { mapState } from 'vuex';

export default {
    props: [
        'title',
        'subtitle',
        'heroImage',
        'heroVideo',
        'url',
        'buttonText',
        'url2',
        'buttonText2',
        'changeTitleColor',
        'changeLinkColor',
        'overlay',
        'isEditable',
    ],
    components: {
        EpiProperty,
        Button,
    },
    computed: {
        ...mapState({
            epiDisableEditing: (state) => state.appContext.modalShowing,
            inEditMode: (state) => state.epiContext.inEditMode,
        }),
        style() {
            var style = {};
            if (this.heroImage) {
                var width = 3000;
                if (window.innerWidth < 1080) {
                    width = 800;
                } else if (window.innerWidth < 1980) {
                    width = 1080;
                } else if (window.innerWidth < 3000) {
                    width = 1980;
                }

                style = {
                    'background-image': 'url(' + this.heroImage + '?w=' + width + ')',
                };
            }
            return style;
        },
    },
};
</script>

<style lang='scss'>
.c-hero {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    position: relative;

    &__overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
    }

    &__button {
        color: $white;
        margin-right: 2em;
        margin-top: 1em;
        @include media-sm {
            margin-top: 0;
        }
        &--colored {
            // magic this to work @extend .button:hover
        }
    }
    &__content-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
    }
    &__content {
        color: white;
        z-index: 1;

        &-title {
            margin-top: 0;
            font-size: 32px;
            line-height: 42px;

            @include media-sm {
                font-size: 52px;
                line-height: 62px;
            }
            @include media-md {
                font-size: 56px;
                line-height: 72px;
            }
            @include media-lg {
                font-size: 72px;
                line-height: 92px;
            }
            &--colored {
                color: $text-color;
            }
            &--less-margin {
                margin-bottom: 1rem;
            }
        }
    }
    &__video {
        position: absolute;
        min-width: 1294px;
        top: 0rex;
        @include media-md {
            min-width: 1598px;
            top: -100px;
        }
        @include media-lg {
            min-width: 1600px;
            top: 0px;
        }
        @media (min-width: 1550px) {
            min-width: 1920px;
            top: -100px;
        }
        @media (min-width: 1920px) {
            min-width: 2500px;
            top: -350px;
        }
        @media (min-width: 2560px) {
            min-width: 3500px;
            top: -330px;
        }
    }
    &--edit {
        height: 750px;
    }
    &--hideoverflow {
        overflow: hidden;
    }
}
</style>
